import React from "react"
import { Link } from "gatsby"

const Header = () => (
    <header className="header">
    <div className="container">
      <div className="top">
        <Link to={`/`}>
          <h2 className="site-name">広文社</h2>
        </Link>
      </div>
      <nav className="nav">
        <ul className="menu">
          <li><Link to={`/`}>ホーム</Link></li>
          <li><Link to={`/ad_cat/`}>交通広告</Link></li>
          <li><Link to={`/company/`}>会社概要</Link></li>
          <li><Link to={`/form/`}>お問い合わせ</Link></li>
        </ul>
      </nav>
    </div>
  </header>
)

export default Header