import React from "react"
import { Link } from "gatsby"

const Footer = () => (
    <footer className="footer">
    <div className="container">
      <nav className="nav">
        <ul className="menu">
          <li><Link to={`/`}>ホーム</Link></li>
          <li><Link to={`/ad_cat/`}>交通広告</Link></li>
          <li><Link to={`/company/`}>会社概要</Link></li>
          <li><Link to={`/form/`}>お問い合わせ</Link></li>
        </ul>
      </nav>
    </div>
    <div class="copyrights"><span>Copyrights. © 広文社</span></div>
  </footer>
)

export default Footer